import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  Dispatch,
} from 'react';
import toast from 'react-hot-toast';
import { usePathname, useRouter } from 'next/navigation';
import { axiosInstance } from '@/network/axiosInstance';
import * as Sentry from '@sentry/react';
import { useGeolocated } from 'react-geolocated';
import axios from 'axios';

type subMenuType = {
  label: string;
  path: string;
};

export type TabsToRenderType = {
  id: number;
  menu: string;
  subMenu?: subMenuType[];
};

type AuthContextType = {
  user: {
    id: string;
    token: string;
    tabsToRender: TabsToRenderType[];
    name: string;
    role: string;
    allowDownload: boolean;
  } | null;
  getOTP: ({ authInput }: { authInput: string }) => Promise<void>;
  validateOTP: ({
    authInput,
    otp,
  }: {
    authInput: string;
    otp: string;
  }) => Promise<void>;
  setFormStep: Dispatch<React.SetStateAction<number>>;
  selectProduct: (clientId: string) => Promise<void>;
  logout: () => void;
  formStep: number;
  loading: boolean;
  showTokenValidationModal: boolean;
};

//@ts-ignore
const AuthContext = createContext<AuthContextType>();

// Custom hook to access the authentication context
export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<{
    id: string;
    token: string;
    tabsToRender: TabsToRenderType[];
    name: string;
    role: string;
    allowDownload: boolean;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [showTokenValidationModal, setShowTokenValidationModal] =
    useState(false);
  const [formStep, setFormStep] = useState(1);
  const router = useRouter();
  const pathname = usePathname();
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
  });

  const revalidateToken = useCallback(
    async (token: string) => {
      try {
        setShowTokenValidationModal(true);
        const res = await axiosInstance.get('/auth/revalidate', {
          headers: {
            'auth-token': token,
          },
        });

        localStorage.setItem('token', res.data.token);
        setUser({
          id: res.data.id,
          token: res.data.token,
          tabsToRender: res.data.tabsToRender,
          name: res.data.name,
          role: res.data.role,
          allowDownload: res.data.allowDownload,
        });
        if (pathname === '/login') {
          router.replace('/select-product');
        }
        setShowTokenValidationModal(false);
      } catch (error) {
        //@ts-ignore
        if (error.response.status === 401) {
          try {
            const res = await axiosInstance.get('/auth/refresh?source=crm', {
              headers: {
                'auth-token': token,
              },
            });
            localStorage.setItem('token', res.data.token);
            setUser({
              id: res.data.id,
              token: res.data.token,
              tabsToRender: res.data.tabsToRender,
              name: res.data.name,
              role: res.data.role,
              allowDownload: res.data.allowDownload,
            });
            if (pathname === '/login') {
              router.replace('/select-product');
            }
            setShowTokenValidationModal(false);
          } catch (error) {
            setShowTokenValidationModal(false);
            localStorage.removeItem('token');
            localStorage.removeItem('clientId');
            setUser(null);
            toast.success('Logged out!');
            setFormStep(1);
            router.replace('/login');
          }
        } else {
          setShowTokenValidationModal(false);
          localStorage.removeItem('token');
          localStorage.removeItem('clientId');
          setUser(null);
          toast.success('Logged out!');
          setFormStep(1);
          router.replace('/login');
        }
      }
    },
    [pathname, router],
  );

  useEffect(() => {
    const getToken = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');

      // Check if the current path is `/public/upload-documents`
      if (pathname === '/public/document-upload') {
        setLoading(false); // Don't change loading state for this path
        return;
      }

      if (token) {
        await revalidateToken(token);
      } else {
        setLoading(false);
        router.replace('/login');
      }
    };

    getToken();
  }, [revalidateToken, router, pathname]);

  const getOTP = async ({ authInput }: { authInput: string }) => {
    setLoading(true);
    const url = '/auth/get_otp';
    try {
      await axiosInstance.post(url, { auth_input: authInput });
      setTimeout(() => {
        setFormStep(2);
        toast.success('OTP sent successfully');
        setLoading(false);
      }, 1000);
    } catch (error) {
      toast.error('Error in sending OTP');
      Sentry.captureException(error);
      setLoading(false);
    }
  };

  const validateOTP = async ({
    authInput,
    otp,
  }: {
    authInput: string;
    otp: string;
  }) => {
    setLoading(true);
    const url = '/auth/validate?source=crm';
    try {
      const res = await axiosInstance.post(url, { auth_input: authInput, otp });
      setUser({
        id: res.data.id,
        token: res.data.token,
        tabsToRender: res.data.tabsToRender,
        name: res.data.name,
        role: res.data.role,
        allowDownload: res.data.allowDownload,
      });
      // * store the token in local storage
      localStorage.setItem('token', res.data.token);
      toast.success('Logged In Successfully!');
      setFormStep(3);
      revalidateToken(res.data.token);
      setLoading(false);
    } catch (error: unknown) {
      toast.error('Error in validating OTP');
      Sentry.captureException(error);
      setLoading(false);
    }
  };

  const selectProduct = async (clientId: string) => {
    try {
      localStorage.setItem('clientId', clientId);
      const res = await axios.get('https://api.ipify.org/?format=json');
      const ip = res.data.ip;
      await axiosInstance.post('/auth/login-event', {
        latitude: coords?.latitude,
        longitude: coords?.longitude,
        ipAddress: ip,
      });
      router.replace('/overview');
    } catch (e) {
      setLoading(false);
      toast.error('Error in selecting product');
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('clientId');
    setUser(null);
    toast.success('Logged out!');
    setFormStep(1);
    router.replace('/login');
  };

  const authContextValue = {
    user,
    getOTP,
    validateOTP,
    formStep,
    setFormStep,
    selectProduct,
    logout,
    loading,
    showTokenValidationModal,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
